import { cloneElement, type ReactElement, type ReactNode } from 'react';

export const ProvidersList = (props: {
  children: ReactNode;
  providers: (ReactElement | null)[];
}): JSX.Element => {
  return props.providers.reduceRight<ReactElement>(
    (content, provider) =>
      provider ? cloneElement(provider, undefined, content) : <>{content}</>,
    <>{props.children}</>
  );
};
